import { template as template_d6e8d1f052744b19ab8d8f92e3ee5826 } from "@ember/template-compiler";
const FKText = template_d6e8d1f052744b19ab8d8f92e3ee5826(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
