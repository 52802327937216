import { template as template_b1c67731f0214e5db8b1637201cdc0a1 } from "@ember/template-compiler";
const FKLabel = template_b1c67731f0214e5db8b1637201cdc0a1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
