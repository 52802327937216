import { template as template_ad2ef9956b294bdea8eabbf0c7986c37 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ad2ef9956b294bdea8eabbf0c7986c37(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
