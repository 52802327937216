import { template as template_04bcd4518fa74110b00429b2dd05e10f } from "@ember/template-compiler";
const WelcomeHeader = template_04bcd4518fa74110b00429b2dd05e10f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
